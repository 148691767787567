import { graphql, Link as GatsbyLink } from "gatsby";
import React from "react";
import { Box, Heading, Link } from "theme-ui";
import {Helmet} from "react-helmet";

export default function IndexPage({ data }: any) {
    return (
        <>
            <Helmet>
                <title>Blog – Karl Erik Hofseth</title>
                <meta name="description" content="My personal blog"/>
            </Helmet>
            {data.allMdx.nodes.map(({ id, excerpt, frontmatter, slug, timeToRead }: any) => (
                <Box
                    key={id}
                    as="article"
                    sx={{
                        mb: 4,
                        p: 3,
                        boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1)",
                        border: "1px solid #d1d1d1",
                        borderRadius: "15px",
                    }}
                >
                    <Link as={GatsbyLink} to={`/blog/${slug}`} style={{textDecoration: "none"}}>
                        <Heading style={{textDecoration: "underline"}}>{frontmatter.title}</Heading>
                        <Box as="p" variant="styles.p" sx={{color: "text"}} >
                            {frontmatter.date}
                        </Box>
                        <Box as="p" variant="styles.p" sx={{color: "text"}}>
                            {excerpt}
                        </Box>
                        <br/>
                        <Box as="p" variant="styles.p" sx={{color: "text", fontSize: 15}}>
                            <i>{timeToRead} {timeToRead > 1 ? "minutes" : "minute"} read</i>
                        </Box>
                    </Link>
                </Box>
            ))}
        </>
    );
}

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date
        }
        slug
        timeToRead
      }
    }
  }
`;
